$background-color: #FFF1EB;
$primary-color: #3D3D3D;
$border-radius: 10px;
$icon-size: 24px;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size: 14px;
$spacing-unit: 8px;

.payment-method {
  &__bank-account {
    position: relative;
    overflow: hidden;
    padding-top: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &-spinner {
      min-height: 60px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex
    }
    
  }
  
  &__bank-account-background {
    border-radius: $border-radius;
    background-color: $background-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 20px;
  }

  &__clock-fast-icon {
    width: $icon-size;
    height: $icon-size;
    position: relative;
  }

  &__back-account-description {
    padding: $spacing-unit * 2;

    &-row {
      display: flex;
    }

    &-heading,
    &-text {
      position: relative;
      font-size: $font-size;
      color: $primary-color;
      margin-left: $spacing-unit;
      font-weight: $font-weight-normal;
    }

    &-heading {
      font-weight: $font-weight-bold;
      margin-left: $spacing-unit;
    }

    &-text {
      text-align: left;
      margin-top: $spacing-unit / 2;
      line-height: normal;
    }
  }
}