/* You can add global styles to this file, and also import other style files */
@import './global-styles/variables';

// Component specific styling
// @import "global-styles/fonts";
@import 'global-styles/modal';
@import 'global-styles/cross-platform';
@import 'global-styles/flex-containers';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');

html,
body,
#app,
#app > div {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}
.btn {
  width: 100%;
  min-height: 60px;
  font-size: 16px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.2;
  }
}

ngb-popover-window {
  .popover-body {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.full-height-container {
  min-height: calc(100vh - 70px);
}

.input-label {
  position: absolute;
  height: 10px;
  left: 2.29%;
  right: 36.64%;
  top: calc(50% - 10px / 2 - 65.5px);

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */

  letter-spacing: 1px;

  color: #161f3d;

  mix-blend-mode: normal;
  opacity: 0.5;
}

// Default button styling
.btn,
.btn:focus,
.btn:hover {
  border-radius: 30px;
  border-color: white;
  color: white;
  height: 60px;
  border-width: 1px;
  background-color: $primary-colour;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 0px 0px 0px 0px;
  &--primary {
    @extend .btn;
    background-color: $primary-colour;
  }
  &--primary-payee {
    @extend .btn;
    background-color: $primary-payee-colour;
  }
  &--secondary {
    @extend .btn;
    background-color: $secondary-colour;
  }
}

// Form styling
.form-heading {
  font-size: 22px;
  color: $form-header-color;
  margin-bottom: 50px;
}
.form-heading-subtext {
  font-size: 12px;
  margin-top: -47px;
  margin-bottom: 47px;
  color: $form-subtext-color;
}
input.form-control {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid $input-form-border-color;
  padding-left: 0px;
  &:focus {
    box-shadow: none;
  }
}
.form-group label {
  color: $input-label-muted-text-color;
  font-size: 16px;
  margin-bottom: 0px;
}

$tiny-font: 0.6875rem;
$mini-font: 0.8125rem;
$small-font: 1rem;
$medium-font: 1.3rem;
$large-font: 1.6rem;
$xlarge-font: 2rem;

.tiny-font {
  font-size: $tiny-font;
}
.mini-font {
  font-size: $mini-font;
}
.small-font {
  font-size: $small-font;
}
.medium-font {
  font-size: $medium-font;
}
.large-font {
  font-size: $large-font;
}
.xlarge-font {
  font-size: $xlarge-font;
}

.container {
  margin: 5%;
  h4 {
    color: $payment-screen-heading-colour;
    font-size: 20px;
    margin-bottom: 32px;
  }
}

.center {
  text-align: center;
}

.overflow-root {
  overflow: visible;
  height: 100vh;
}

.slide-container-underlay {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  &.showUnderlay {
    background-color: transparent;
  }
  .slide-in-container {
    padding-top: 38px;
    background-color: white;
    align-self: flex-end;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
}

p {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $text-default;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
}

label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 1px;
  color: #fc4c02;
  text-transform: uppercase;
}

input {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  align-self: stretch;
  outline-color: transparent;
  padding: 4px 0px 6px 0px;
  border-color: #e6e6e6;
  border-radius: 0px;
  /* identical to box height */

  color: #7a7a7a;

  &:focus {
    outline: none;
    border-width: 0px 0px 1px 0px;
    border-color: $primary-colour !important;
  }
}

.flex-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
