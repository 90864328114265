$image-size: 98px;

.avatar {
  width: $image-size;
  height: $image-size;
  border-radius: calc($image-size / 2);
  align-self: center;
  margin-bottom: 16px;
  border: 2px solid #BBC2CA;
}
