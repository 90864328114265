.helpImprove-title {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 35px;
  }
  
  .helpImprove-text {
    color: #575757;
  }
  