// handles notch display issues
@supports (margin-top: env(safe-area-inset-top)) {
  :root {
    --topPad: calc(env(safe-area-inset-top, 0px) / 2);
  }

  .tenderline-push-below-header {
    margin-top: calc(var(--topPad) + 77px) !important;
  }

  .item-quantity {
    top: calc((var(--topPad) / 2) + 28px) !important;
  }

  .header {
    min-height: calc(var(--topPad) + 77px) !important;
  }
}
