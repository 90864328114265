.amount-view {
    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__loader {
        height: 120px;
        margin-bottom: 28px;
        width: 120px;
    }
}