@import '../../global-styles/variables';

.deployment-notification {
    background-color: $primary-colour;
    padding-top: 150px;

    &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;

        &__animation {
            width: 112px;
            height: 112px;
        }

        &__heading {
            color: $text-white;
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 10px;
            margin-top: 30px;
        }

        &__text {
            color: $text-white;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 20px;

            &--bold {
                font-weight: 700;
            }
        }
    }

}