.threeD-secure-redirect {
    &__body {
        justify-content: center;
        align-items: center;
    }

    &__loader {
        height: 120px;
        margin-bottom: 28px;
        width: 120px;
    }
}

