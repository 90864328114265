.common-error {
    &__title {
        font-weight: 700;
        text-align: center;
    }

    &__top-content {
        display: flex;
        flex-direction: column;
        flex: 3;
        justify-content: center;
    }

    &__bottom-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
    }

    &__bottom-text {
        font-weight: 700;
        color: #3D3D3D;
    }

    &__main-message {
        color: #3D3D3D;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        text-align: center;
    }
}
