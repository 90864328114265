#mail-text {
  margin-top: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #7a7a7a;
}

#mail-receipt-text {
  margin-top: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #3d3d3d;
}
