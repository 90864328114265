@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

.stripe-setup-success {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FC4C02;
    display: flex;
    justify-content: center;
    align-items: center;

    &__loading-spinner {
        content: '';
        align-self: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-top-color: #FC4C02;
        animation: spinner 0.6s linear infinite;
    }

    &__loading-text {
        color: #fff;
        font-size: 20px;
        margin-left: 10px;
    }
}


  
