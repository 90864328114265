@import '../../global-styles/variables';


.card-form {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__submit-btn-container {
    min-height: 92px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.is-premium-card {
  margin-top: 18px;
  flex: 1;

  &--switch {
    margin-left: 0px !important;
    width: calc(100% + 12px);
    justify-content: space-between;
  }

}

.StripeInput {
  height: 24px;
  margin-top: 8px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e6e6e6;
}

.StripeElement--focus {
  border-width: 0px 0px 1px 0px;
  border-color: $primary-colour;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

