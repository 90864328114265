.amount-navbar {
    margin-bottom: 30px;

    &-logo {
        display: flex; 
        justify-content: center;
        cursor: pointer;

        &__heading {
            align-items: center;
            color: var(--text-default);
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 0;
            margin-right: 3px;
            margin-top: 0px;
            text-align: center;

            &--light {
                color: white;
            }
        }

        &__image {
            height: 14px;
        }
    }

    &-list {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        list-style-type:none;
        margin-block-end: 0;
        margin-block-start: 16px;
        padding-inline-end: 16px;
        padding-inline-start: 16px;

        &__item {
            margin-top: 0;
            padding-top: 0;

            &-link {
                color: var(--text-default);
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                margin: 0 8px;
                text-decoration: none;

                &--light {
                    color: white;
                }
            }

            &-divider {
                height: 14px;
                border-right: 1px solid;
                border-right-color: var(--text-default);

                &--light {
                    border-right-color: white;
                }
            }
        }
    }
}
