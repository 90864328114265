.page {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 375px;
  max-height: 667px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
