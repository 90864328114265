@import '../../global-styles/variables';

#providers-description {
  text-align: start;
  font-size: 12px;
  line-height: 20px;
}

#providers-logos {
  margin-top: 10%;
  width: 100%;
  align-self: center;
  object-fit: contain;
}
