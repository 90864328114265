/*
 * For establishing mobile flex containers
 */

body {
  display: flex;
  flex: 1;
  flex-grow: 1;
}

#root {
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.flex-root {
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;

  .inner {
    align-self: center;
    max-width: 375px;
    height: 667px;
    flex-direction: column;
    border: 1px solid black;
    background-color: white;
  }

  .mobile {
    max-width: 500px;
  }
}

.flex-body {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px;
}

.fixed-to-bottom {
  display: flex;
  margin: 0px 30px 30px 30px;
}
