.email-verification {
    &__loader {
        height: 120px;
        margin-bottom: 28px;
        width: 120px;
    }

    &__heading {
        line-height: normal;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 10px;
    }

    &__description {
        color: var(--text-dark);
        font-weight: 400;
        line-height: normal;
    }

    &__btn-container {
        margin-top: 30px;
        width: 100%;
    }

    &__qr-code {
        align-items: center;
        flex: 1;
        height: 150px;
        width: 150px;
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__loading {
        justify-content: center;
    }

    &__success {
        justify-content: flex-start;
        padding-top: 40px;
    }

}