@import "./variables";

.modal-dialog-centered {
  margin: 0;
  min-height: 100%;
  .modal-content {
    max-width: 100%;
    width: 100%;
    margin: 0;
    align-self: flex-end;
    border-radius: 0;
  }
}

.modal-header {
  // background-color: @alterra-default-modal-background-color;
  .modal-title {
    // color: @alterra-primary-font-color;
  }
  svg {
    // fill: @alterra-button-background-color;
  }
}
.modal-body {
  // background-color: @alterra-default-modal-background-color;
  // color: @alterra-faded-font-color;
  .btn {
    // border: 1px solid @alterra-button-background-color;
    // background-color: @alterra-button-background-color;
    // color: @alterra-button-font-color;
    border-radius: 0;
  }
}
