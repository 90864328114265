@import '../../../global-styles/variables';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

#loading-spinner {
  content: '';
  align-self: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: $primary-colour;
  animation: spinner 0.6s linear infinite;
}
